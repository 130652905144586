import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import applianceTypes from '../data/applianceTypes';
import BrandsWeService from '../components/BrandsWeService';

const Home = ({ openBookingForm, mapboxApiKey }) => {
  const serviceAreas = [
    { name: 'London', longitude: -0.1276, latitude: 51.5074 },
    { name: 'Essex', longitude: 0.4724, latitude: 51.7344 },
    { name: 'Hertfordshire', longitude: -0.2, latitude: 51.8},
    { name: 'Kent', longitude: 0.5217, latitude: 51.2787 },
    { name: 'Surrey', longitude: -0.5, latitude: 51.3148 },
  ];

  const applianceTypeToUrl = {
    'Washing Machine': '/services/washing-machine',
    'Dryer': '/services/dryer',
    'Dishwasher': '/services/dishwasher',
    'Oven': '/services/oven',
    'Cooker': '/services/cooker',
    'Hob': '/services/hob',
    'Cooker Hood': '/services/cooker-hood',
    'Fridge': '/services/fridge',
    'Freezer': '/services/freezer',
    'Fridge-Freezer': '/services/fridge-freezer',
    'Microwave': '/services/microwave',
    // Add more mappings as needed
  };

  const ServiceCard = ({ icon, title, description, link }) => {
    const [{ x }, api] = useSpring(() => ({ x: 0 }));

    const bind = useDrag(({ down, movement: [mx] }) => {
      api.start({ x: down ? mx : 0, immediate: down });
      if (mx > 100 && !down) {
        openBookingForm();
      }
    });

    return (
      <animated.div
        {...bind()}
        style={{
          x,
          touchAction: 'pan-y',
        }}
        className="rounded-lg shadow-md p-4 sm:p-6 text-center hover:shadow-lg transition duration-300 cursor-grab active:cursor-grabbing bg-white"
      >
        <i className={`${icon} text-3xl sm:text-4xl text-primary-500 mb-2 sm:mb-4`}></i>
        <h3 className="text-lg sm:text-xl font-semibold mb-2">{title}</h3>
        <p className="mb-4 text-sm sm:text-base">{description}</p>
        <Link to={link} className="text-primary-500 hover:text-primary-600 font-medium text-sm sm:text-base">Learn More</Link>
        <div className="mt-4 text-sm text-gray-500">Slide to book &rarr;</div>
      </animated.div>
    );
  };

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Southeast Appliances - Expert Home Appliance Repair Services</title>
        <meta name="description" content="Professional domestic appliance repair services across Southeast UK. Fast, reliable repairs for all types of home appliances, with a 90-day guarantee." />
        <meta name="keywords" content="domestic appliance repair, home appliance service, UK appliance experts, washing machine repair, oven repair, dishwasher repair, fridge repair" />
      </Helmet>

      {/* Services Overview */}
      <section className="py-8 sm:py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
            <ServiceCard
              icon="fas fa-wrench"
              title="Repair"
              description="Swift and efficient repair services for all types of domestic appliances, backed by our 90-day guarantee."
              link="/services"
            />
            <ServiceCard
              icon="fas fa-box"
              title="Installation"
              description="Expert installation of new home appliances, ensuring optimal performance from day one."
              link="/services"
            />
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="bg-white py-8 sm:py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Why Choose Southeast Appliances?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
            {[
              { icon: "fas fa-clock", title: "Fast Response", description: "Quick support for urgent repairs, minimising inconvenience in your home." },
              { icon: "fas fa-tools", title: "Experienced Engineers", description: "Our team of skilled engineers has extensive experience with all major brands and models." },
              { icon: "fas fa-pound-sign", title: "Competitive Pricing", description: "Transparent, fair pricing with no hidden fees. Repairs start from just £80." },
              { icon: "fas fa-shield-alt", title: "90-Day Guarantee", description: "All our repairs come with a 90-day guarantee for your peace of mind." }
            ].map((item, index) => (
              <div key={index} className="bg-gray-100 rounded-lg shadow-md p-4 sm:p-6 text-center hover:shadow-lg transition duration-300">
                <i className={`${item.icon} text-3xl sm:text-4xl text-primary-500 mb-2 sm:mb-4`}></i>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">{item.title}</h3>
                <p className="text-sm sm:text-base">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Types of Appliances We Service */}
      <section className="py-8 sm:py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Types of Appliances We Service</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {applianceTypes.map((type, index) => (
              <Link 
                key={index} 
                to={applianceTypeToUrl[type] || '/services'}
                className="bg-white rounded-lg shadow-md p-3 sm:p-4 text-center hover:shadow-lg transition duration-300 block"
              >
                <p className="text-gray-800 text-sm sm:text-base">{type}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Brands We Service */}
      <BrandsWeService />

      {/* Service Areas */}
      <section className="bg-white py-8 sm:py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Areas We Serve</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {mapboxApiKey && serviceAreas.map((area, index) => (
              <Link key={index} to={`/service-areas/${area.name.toLowerCase()}`} className="bg-gray-100 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300">
                <div className="h-40 sm:h-48">
                  <Map
                    mapboxAccessToken={mapboxApiKey}
                    initialViewState={{
                      longitude: area.longitude,
                      latitude: area.latitude,
                      zoom: 8
                    }}
                    style={{width: '100%', height: '100%'}}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                  />
                </div>
                <div className="p-4">
                  <h3 className="text-lg sm:text-xl font-semibold text-center">{area.name}</h3>
                </div>
              </Link>
            ))}
          </div>
          <div className="text-center mt-6 sm:mt-8">
            <Link to="/service-areas" className="text-primary-500 hover:text-primary-600 font-medium">View Full Coverage Map</Link>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-primary-600 text-white py-8 sm:py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Ready to Get Your Appliance Fixed?</h2>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8">Our expert engineers are just a click away. Book your repair now and enjoy our 90-day guarantee!</p>
          <button onClick={openBookingForm} className="bg-white text-primary-600 font-bold py-2 px-6 sm:py-3 sm:px-8 rounded-full text-base sm:text-lg hover:bg-gray-200 transition duration-300">
            Book a Repair
          </button>
          <p className="mt-4">Or call us at: <a href="tel:+442078702206" className="font-bold">0330 818 3946</a></p>
        </div>
      </section>
    </div>
  );
};

export default Home;
