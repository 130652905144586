import React from 'react';

const WhyChooseUs = () => {
  const reasons = [
    { text: 'Experienced and Certified Engineers', icon: 'fa-user-graduate' },
    { text: 'Fast Response Times', icon: 'fa-bolt' },
    { text: 'Competitive Pricing', icon: 'fa-pound-sign' },
    { text: 'High-Quality Replacement Parts', icon: 'fa-cogs' },
    { text: 'Excellent Customer Service', icon: 'fa-star' }
  ];

  return (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4 text-primary-500">Why Choose Us?</h3>
      <ul className="space-y-2">
        {reasons.map((reason, index) => (
          <li key={index} className="flex items-center">
            <i className={`fas ${reason.icon} text-primary-500 mr-2`}></i>
            <span>{reason.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhyChooseUs;
