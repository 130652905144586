import React from 'react';
import { InputWithIcon, SelectWithIcon } from './FormComponents';
import { applianceTypes, applianceBrands } from '../config';

const appliances = applianceTypes.map(type => ({ value: type, label: type }));
const brands = applianceBrands.map(brand => ({ value: brand, label: brand }));

const InstallationForm = ({ formData, handleInputChange, errors }) => {
  return (
    <>
      <SelectWithIcon
        icon="fa-cog"
        name="applianceType"
        value={formData.applianceType}
        onChange={handleInputChange}
        options={appliances}
        required
        label="Appliance Type"
        error={errors.applianceType}
      />
      <SelectWithIcon
        icon="fa-industry"
        name="applianceBrand"
        value={formData.applianceBrand}
        onChange={handleInputChange}
        options={brands}
        required
        label="Appliance Brand"
        error={errors.applianceBrand}
      />
      <InputWithIcon
        icon="fa-ruler-combined"
        type="text"
        name="installationSpace"
        value={formData.installationSpace}
        onChange={handleInputChange}
        placeholder="Describe the installation space"
        required
        label="Installation Space"
        error={errors.installationSpace}
      />
      <InputWithIcon
        icon="fa-clipboard"
        type="textarea"
        name="installationNotes"
        value={formData.installationNotes}
        onChange={handleInputChange}
        placeholder="Any additional notes for installation"
        label="Installation Notes"
        error={errors.installationNotes}
      />
    </>
  );
};

export default InstallationForm;
