import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Page Not Found - Local Fridge Repair</title>
        <meta name="description" content="The page you're looking for doesn't exist. Please check the URL and try again." />
      </Helmet>
      <h1 className="text-4xl font-bold mb-6 text-primary-600">404 - Page Not Found</h1>
      <p className="mb-6 text-gray-700">We're sorry, but the page you're looking for doesn't exist. This could be due to:</p>
      <ul className="list-disc list-inside mb-6 text-gray-700">
        <li>An outdated bookmark</li>
        <li>A mistyped URL</li>
        <li>A broken link on our site</li>
      </ul>
      <p className="mb-6 text-gray-700">You can try:</p>
      <ul className="list-disc list-inside mb-6 text-gray-700">
        <li>Checking the URL for typos</li>
        <li>Going back to the <Link to="/" className="text-primary-500 hover:text-primary-600">homepage</Link></li>
        <li>Using the navigation menu to find what you're looking for</li>
      </ul>
      <Link to="/" className="bg-primary-500 text-white font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300">
        Go to Homepage
      </Link>
    </div>
  );
};

export default NotFound;