import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items }) => {
  const truncate = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  return (
    <nav className="text-xs sm:text-sm mb-4 overflow-hidden pt-32 sm:pt-40"> {/* Doubled padding-top here */}
      <ol className="list-none p-0 flex flex-wrap items-center">
        <li className="flex items-center mr-1 mb-1">
          <Link to="/" className="text-primary-500 hover:text-primary-700">Home</Link>
        </li>
        {items.map((item, index) => (
          <li key={index} className="flex items-center mr-1 mb-1">
            <span className="mx-1 text-gray-500">/</span>
            {index === items.length - 1 ? (
              <span className="text-gray-700">{truncate(item.label, 20)}</span>
            ) : (
              <Link to={item.path} className="text-primary-500 hover:text-primary-700">
                {truncate(item.label, 20)}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;