import React from 'react';

const CTAButton = ({ text, onClick, className = '' }) => {
  return (
    <button
      onClick={onClick}
      className={className}
    >
      {text}
    </button>
  );
};

export default CTAButton;