import React from 'react';
import { InputWithIcon, SelectWithIcon } from './FormComponents';
import { applianceTypes, applianceBrands } from '../config';

const appliances = applianceTypes.map(type => ({ value: type, label: type }));
const brands = applianceBrands.map(brand => ({ value: brand, label: brand }));

const RepairForm = ({ formData, handleInputChange, errors }) => {
  return (
    <>
      <SelectWithIcon
        icon="fa-cog"
        name="applianceType"
        value={formData.applianceType}
        onChange={handleInputChange}
        options={appliances}
        required
        label="Appliance Type"
        error={errors.applianceType}
      />
      <SelectWithIcon
        icon="fa-industry"
        name="applianceBrand"
        value={formData.applianceBrand}
        onChange={handleInputChange}
        options={brands}
        required
        label="Appliance Brand"
        error={errors.applianceBrand}
      />
      <InputWithIcon
        icon="fa-exclamation-circle"
        type="textarea"
        name="faultDescription"
        value={formData.faultDescription}
        onChange={handleInputChange}
        placeholder="Describe the fault"
        required
        label="Fault Description"
        error={errors.faultDescription}
      />
    </>
  );
};

export default RepairForm;
