const applianceDetails = {
  'Washing Machine': {
    description: "Expert washing machine repair services for all major brands. We fix common issues like leaks, spin problems, and faulty electronics.",
    serviceDetails: [
      "Drum and belt replacement",
      "Motor repair and replacement",
      "Control board diagnostics and repair",
      "Leak detection and fix",
      "Door seal replacement"
    ],
    brands: [
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Miele", logo: "miele.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Indesit", logo: "indesit.png" },
      { name: "Zanussi", logo: "zanussi.png" },
      { name: "Beko", logo: "beko.png" }
    ]
  },
  'Dryer': {
    description: "Professional dryer repair services to keep your clothes drying efficiently. We handle both vented and condenser dryers.",
    serviceDetails: [
      "Heating element replacement",
      "Thermostat and sensor repair",
      "Belt and drum issues",
      "Vent cleaning and maintenance",
      "Motor replacement"
    ],
    brands: [
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Miele", logo: "miele.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Indesit", logo: "indesit.png" },
      { name: "Zanussi", logo: "zanussi.png" },
      { name: "Beko", logo: "beko.png" }
    ]
  },
  'Dishwasher': {
    description: "Comprehensive dishwasher repair services for all types and brands. We resolve issues from poor cleaning performance to water leaks.",
    serviceDetails: [
      "Pump and motor repairs",
      "Spray arm cleaning and replacement",
      "Door seal replacement",
      "Control panel and electronics repair",
      "Drainage system unclogging"
    ],
    brands: [
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Miele", logo: "miele.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Indesit", logo: "indesit.png" },
      { name: "Zanussi", logo: "zanussi.png" },
      { name: "Beko", logo: "beko.png" },
      { name: "Whirlpool", logo: "whirlpool.png" }
    ]
  },
  'Fridge': {
    description: "Expert Fridge repair services to keep your food fresh and your energy bills low. We handle all types of fridges and freezers.",
    serviceDetails: [
      "Compressor repair and replacement",
      "Thermostat and temperature control fixes",
      "Defrost system repairs",
      "Ice maker troubleshooting",
      "Door seal replacement"
    ],
    brands: [
      { name: "Samsung", logo: "samsung.png" },
      { name: "LG", logo: "lg.png" },
      { name: "Whirlpool", logo: "whirlpool.png" },
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Miele", logo: "miele.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Beko", logo: "beko.png" }
    ]
  },
  'Oven': {
    description: "Professional oven repair services for gas and electric models. We ensure your oven heats evenly and functions safely.",
    serviceDetails: [
      "Heating element replacement",
      "Thermostat calibration and repair",
      "Door hinge and seal replacement",
      "Control panel and electronics repair",
      "Gas oven safety checks and repairs"
    ],
    brands: [
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Neff", logo: "neff.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Indesit", logo: "indesit.png" },
      { name: "Zanussi", logo: "zanussi.png" },
      { name: "Beko", logo: "beko.png" },
      { name: "Whirlpool", logo: "whirlpool.png" }
    ]
  },
  'Cooker Hood': {
    description: "Expert cooker hood repair services to keep your kitchen ventilated and odor-free. We handle all types of extractor fans and hoods.",
    serviceDetails: [
      "Motor replacement",
      "Fan blade repair or replacement",
      "Filter cleaning and replacement",
      "Light fixture repair",
      "Control panel troubleshooting"
    ],
    brands: [
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Neff", logo: "neff.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Indesit", logo: "indesit.png" },
      { name: "Zanussi", logo: "zanussi.png" },
      { name: "Beko", logo: "beko.png" },
      { name: "Elica", logo: "elica.png" }
    ]
  },
  'Hob': {
    description: "Comprehensive hob repair services for gas, electric, and induction models. We ensure safe and efficient cooking for your kitchen.",
    serviceDetails: [
      "Gas burner cleaning and repair",
      "Electric element replacement",
      "Induction coil diagnostics and repair",
      "Temperature sensor calibration",
      "Control panel and touch control repairs"
    ],
    brands: [
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Neff", logo: "neff.png" },
      { name: "AEG", logo: "aeg.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Indesit", logo: "indesit.png" },
      { name: "Zanussi", logo: "zanussi.png" },
      { name: "Beko", logo: "beko.png" },
      { name: "Whirlpool", logo: "whirlpool.png" }
    ]
  },
  'Microwave': {
    description: "Expert microwave repair services for all types and brands. We fix issues from heating problems to turntable malfunctions.",
    serviceDetails: [
      "Magnetron replacement",
      "Door switch and latch repair",
      "Turntable motor replacement",
      "Control panel and display repair",
      "Cavity and interior light replacement"
    ],
    brands: [
      { name: "Samsung", logo: "samsung.png" },
      { name: "LG", logo: "lg.png" },
      { name: "Bosch", logo: "bosch.png" },
      { name: "Siemens", logo: "siemens.png" },
      { name: "Whirlpool", logo: "whirlpool.png" },
      { name: "Hotpoint", logo: "hotpoint.png" },
      { name: "Beko", logo: "beko.png" }
    ]
  }
};

export default applianceDetails;
