import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import locations from '../data/locations';
import applianceTypes from '../data/applianceTypes';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2VhLWNoYXJsaWUiLCJhIjoiY20xc2dlbzZyMDY3bzJqcjQwcnUxOXdidCJ9.1KGbWLCthsQFmo_SHbc-Yw';

const QuoteBar = ({ openBookingForm }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const quoteBarRef = useRef(null);
  const sentinelRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: MAPBOX_TOKEN,
      types: 'postcode',
      country: 'GB',
      placeholder: 'Enter your postcode',
    });

    geocoder.addTo(geocoderContainerRef.current);

    geocoder.on('result', (e) => {
      const [lng, lat] = e.result.center;
      const postcode = e.result.text;
      const closestLocation = findClosestLocation(lng, lat);
      setSelectedLocation(closestLocation);
      
      if (!isPostcodeInCoveredArea(postcode)) {
        setShowPopup(true);
      }
    });

    const handleScroll = () => {
      if (quoteBarRef.current && sentinelRef.current) {
        const quoteBarTop = quoteBarRef.current.getBoundingClientRect().top;
        const stickyTop = sentinelRef.current.getBoundingClientRect().top;
        setIsSticky(stickyTop <= 80 && quoteBarTop <= 80);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Custom styling for the geocoder input
    const geocoderInput = geocoderContainerRef.current.querySelector('.mapboxgl-ctrl-geocoder--input');
    if (geocoderInput) {
      geocoderInput.classList.add('custom-geocoder-input');
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      geocoder.onRemove();
    };
  }, []);

  // Reset sticky state when navigating to a new page
  useEffect(() => {
    setIsSticky(false);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const findClosestLocation = (lng, lat) => {
    let closestLocation = null;
    let minDistance = Infinity;

    locations.forEach(location => {
      location.regions.forEach(region => {
        region.towns.forEach(town => {
          const distance = calculateDistance(lng, lat, town.lng, town.lat);
          if (distance < minDistance) {
            minDistance = distance;
            closestLocation = { location, region, town };
          }
        });
      });
    });

    return closestLocation;
  };

  const calculateDistance = (lng1, lat1, lng2, lat2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lng2 - lng1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return R * c; // Distance in km
  };

  const isPostcodeInCoveredArea = (postcode) => {
    const coveredPostcodes = locations.flatMap(location => 
      location.regions.flatMap(region => region.postcodes)
    );
    return coveredPostcodes.some(prefix => postcode.toUpperCase().startsWith(prefix));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!selectedType) {
      setErrorMessage('Please select an appliance type.');
      return;
    }

    if (selectedType && selectedLocation) {
      const { location, region, town } = selectedLocation;
      const locationSlug = location.name.toLowerCase().replace(/ /g, '-');
      const regionSlug = region.name.toLowerCase().replace(/ /g, '-');
      const townSlug = town.name.toLowerCase().replace(/ /g, '-');
      const serviceSlug = selectedType.toLowerCase().replace(/[^a-z0-9]+/g, '-');

      const url = `/service-areas/${locationSlug}${locationSlug !== regionSlug ? `/${regionSlug}` : ''}/${townSlug}/${serviceSlug}-repair`;
      
      // Force reload by adding a timestamp to the URL
      const timestamp = Date.now();
      const reloadUrl = `${url}?t=${timestamp}`;
      
      navigate(reloadUrl, { replace: true });
      window.location.reload(); // Force a full page reload
    } else {
      openBookingForm();
    }
  };

  return (
    <div ref={quoteBarRef} className="quote-bar-wrapper">
      <div ref={sentinelRef} className="sticky-sentinel" />
      <div className={`bg-primary-500 py-4 ${isSticky ? 'sticky-quote-bar' : ''}`} id="quote-bar">
        <div className="container mx-auto px-4">
          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-2">
            <div className="text-white text-sm font-medium w-full sm:w-auto text-center sm:text-left mb-2 sm:mb-0">
              We need an Engineer to repair our
            </div>
            <div className="w-full sm:w-auto">
              <select 
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="w-full sm:w-auto p-2 border-0 rounded-md text-sm font-medium bg-white text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-300 appearance-none"
              >
                <option value="" disabled>Select Appliance Type</option>
                {applianceTypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div className="text-white text-sm font-medium">
              in
            </div>
            <div ref={geocoderContainerRef} className="w-full sm:w-auto custom-geocoder-container"></div>
            <button 
              type="submit" 
              className="w-full sm:w-auto bg-white text-primary-500 font-bold text-sm py-2 px-4 rounded-md hover:bg-gray-100 transition duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300"
            >
              Get A Quote
            </button>
          </form>
          {errorMessage && (
            <div className="text-white text-sm mt-2 text-center">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
            <h3 className="text-xl font-bold mb-4">Area Not Typically Covered</h3>
            <p className="mb-4">We do not typically cover this area. However, on request, we may be able to provide service. Please call us for more information.</p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-primary-500 text-white px-4 py-2 rounded hover:bg-primary-600 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteBar;
