import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoic2VhLWNoYXJsaWUiLCJhIjoiY20xc2dlbzZyMDY3bzJqcjQwcnUxOXdidCJ9.1KGbWLCthsQFmo_SHbc-Yw';

const InteractiveMap = ({ center, zoom, markers }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (!markers || markers.length === 0) return; // don't initialize if no markers

    console.log('Map center:', center);
    console.log('Markers:', markers);

    const mapCenter = markers[0] ? [markers[0].lng, markers[0].lat] : center;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: mapCenter,
      zoom: zoom || 12 // Set a default zoom level if not provided
    });

    // Add navigation control (zoom buttons)
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.current.on('load', async () => {
      // Add markers
      markers.forEach((marker) => {
        new mapboxgl.Marker()
          .setLngLat([marker.lng, marker.lat])
          .addTo(map.current);
      });

      // Generate random points for van icons
      const vanPoints = Array(3).fill().map(() => getRandomPoint(mapCenter));

      // Add the van icons
      map.current.loadImage(
        'https://cdn-icons-png.flaticon.com/512/3774/3774278.png',
        async (error, image) => {
          if (error) throw error;
          map.current.addImage('van-icon', image);
          map.current.addSource('vans', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': vanPoints.map((point, index) => ({
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates': point
                },
                'properties': {
                  'id': index
                }
              }))
            }
          });
          map.current.addLayer({
            'id': 'vans',
            'type': 'symbol',
            'source': 'vans',
            'layout': {
              'icon-image': 'van-icon',
              'icon-size': 0.05
            }
          });

          // Add routes from van points to center
          for (let i = 0; i < vanPoints.length; i++) {
            const route = await getRoute(vanPoints[i], mapCenter);
            map.current.addSource(`route-${i}`, {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': route
              }
            });
            map.current.addLayer({
              'id': `route-${i}`,
              'type': 'line',
              'source': `route-${i}`,
              'layout': {
                'line-join': 'round',
                'line-cap': 'round'
              },
              'paint': {
                'line-color': '#888',
                'line-width': 2
              }
            });
          }

          // Fit the map to show all vans and the destination
          const bounds = new mapboxgl.LngLatBounds();
          vanPoints.forEach(point => bounds.extend(point));
          bounds.extend(mapCenter);

          map.current.fitBounds(bounds, {
            padding: 50, // Add some padding around the bounds
            maxZoom: 14 // Limit the max zoom level
          });
        }
      );
    });
  }, [center, zoom, markers]);

  // Helper function to get a random point near the given point
  const getRandomPoint = (point) => {
    const radius = 0.02; // approximately 2km
    const r = radius * Math.sqrt(Math.random());
    const theta = Math.random() * 2 * Math.PI;
    return [
      point[0] + r * Math.cos(theta),
      point[1] + r * Math.sin(theta)
    ];
  };

  // Helper function to get a route using Mapbox Directions API
  const getRoute = async (start, end) => {
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
      { method: 'GET' }
    );
    const json = await query.json();
    return json.routes[0].geometry;
  };

  return (
    <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />
  );
};

export default InteractiveMap;
