import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import locations from '../data/locations';

// Import all brand logos
import aegLogo from '../assets/logos/brand-logos/aeg.png';
import bekoLogo from '../assets/logos/brand-logos/beko.png';
import bellingLogo from '../assets/logos/brand-logos/belling.png';
import blombergLogo from '../assets/logos/brand-logos/blomberg.png';
import boschLogo from '../assets/logos/brand-logos/bosch.png';
import britanniaLogo from '../assets/logos/brand-logos/britannia.png';
import capleLogo from '../assets/logos/brand-logos/caple.png';
import electroluxLogo from '../assets/logos/brand-logos/electrolux.png';
import elicaLogo from '../assets/logos/brand-logos/elica.png';
import falconLogo from '../assets/logos/brand-logos/falcon.png';
import fisherPaykelLogo from '../assets/logos/brand-logos/fisher-paykel.png';
import haierLogo from '../assets/logos/brand-logos/haier.png';
import hisenseLogo from '../assets/logos/brand-logos/hisense.png';
import hooverLogo from '../assets/logos/brand-logos/hoover.png';
import hotpointLogo from '../assets/logos/brand-logos/hotpoint.png';
import indesitLogo from '../assets/logos/brand-logos/indesit.png';
import lamonaLogo from '../assets/logos/brand-logos/lamona.png';
import leisureLogo from '../assets/logos/brand-logos/leisure.png';
import lgLogo from '../assets/logos/brand-logos/lg.png';
import liebherrLogo from '../assets/logos/brand-logos/liebherr.png';
import lofraLogo from '../assets/logos/brand-logos/lofra.png';
import mieleLogo from '../assets/logos/brand-logos/miele.png';
import neffLogo from '../assets/logos/brand-logos/neff.png';
import rangemasterLogo from '../assets/logos/brand-logos/rangemaster.png';
import samsungLogo from '../assets/logos/brand-logos/samsung.png';
import siemensLogo from '../assets/logos/brand-logos/siemens.png';
import smegLogo from '../assets/logos/brand-logos/smeg.png';
import stovesLogo from '../assets/logos/brand-logos/stoves.png';
import whirlpoolLogo from '../assets/logos/brand-logos/whirlpool.png';
import zanussiLogo from '../assets/logos/brand-logos/zanussi.png';

const logoMap = {
  'aeg.png': aegLogo,
  'beko.png': bekoLogo,
  'belling.png': bellingLogo,
  'blomberg.png': blombergLogo,
  'bosch.png': boschLogo,
  'britannia.png': britanniaLogo,
  'caple.png': capleLogo,
  'electrolux.png': electroluxLogo,
  'elica.png': elicaLogo,
  'falcon.png': falconLogo,
  'fisher-paykel.png': fisherPaykelLogo,
  'haier.png': haierLogo,
  'hisense.png': hisenseLogo,
  'hoover.png': hooverLogo,
  'hotpoint.png': hotpointLogo,
  'indesit.png': indesitLogo,
  'lamona.png': lamonaLogo,
  'leisure.png': leisureLogo,
  'lg.png': lgLogo,
  'liebherr.png': liebherrLogo,
  'lofra.png': lofraLogo,
  'miele.png': mieleLogo,
  'neff.png': neffLogo,
  'rangemaster.png': rangemasterLogo,
  'samsung.png': samsungLogo,
  'siemens.png': siemensLogo,
  'smeg.png': smegLogo,
  'stoves.png': stovesLogo,
  'whirlpool.png': whirlpoolLogo,
  'zanussi.png': zanussiLogo,
};

// ... rest of the file remains unchanged

const ServiceTypeTemplate = ({ serviceType, title, description, serviceDetails, brands, openBookingForm }) => {
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeRegion, setActiveRegion] = useState(null);
  const formattedServiceType = serviceType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

  const handleLocationClick = (location) => {
    setActiveLocation(location === activeLocation ? null : location);
    setActiveRegion(null);
  };

  const handleRegionClick = (region) => {
    setActiveRegion(region === activeRegion ? null : region);
  };

  const generateServiceLink = (location, region, town) => {
    const locationSlug = location.name.toLowerCase().replace(/ /g, '-');
    const regionSlug = region.name.toLowerCase().replace(/ /g, '-');
    const townSlug = town.name.toLowerCase().replace(/ /g, '-');
    const serviceSlug = serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-');

    if (location.name === region.name) {
      return `/service-areas/${locationSlug}/${townSlug}/${serviceSlug}-repair`;
    } else {
      return `/service-areas/${locationSlug}/${regionSlug}/${townSlug}/${serviceSlug}-repair`;
    }
  };

  const renderLocationLinks = (location) => (
    <div className="location mb-4">
      <button
        onClick={() => handleLocationClick(location.name)}
        className={`location-button w-full text-left px-4 py-2 bg-primary-500 text-white rounded-t-lg ${activeLocation === location.name ? 'bg-primary-600' : ''}`}
      >
        {location.name}
      </button>
      {activeLocation === location.name && (
        <div className="regions bg-white border border-primary-500 rounded-b-lg">
          {location.regions.map((region) => (
            <div key={region.name} className="region">
              {location.name !== region.name && (
                <button
                  onClick={() => handleRegionClick(region.name)}
                  className={`region-button w-full text-left px-4 py-2 hover:bg-gray-100 ${activeRegion === region.name ? 'bg-gray-200' : ''}`}
                >
                  {region.name}
                </button>
              )}
              {(activeRegion === region.name || location.name === region.name) && (
                <div className="towns pl-4">
                  {region.towns.map((town) => (
                    <div key={town.name} className="town">
                      <Link 
                        to={generateServiceLink(location, region, town)}
                        className="town-button w-full text-left px-4 py-2 hover:bg-gray-100 block text-primary-500 hover:text-primary-700"
                      >
                        {town.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
        <title>{title || `${formattedServiceType} Repair Services | Southeast Appliances`}</title>
        <meta name="description" content={description || `Professional ${formattedServiceType.toLowerCase()} repair services across various locations. Fast, reliable, and expert repairs for all types of ${formattedServiceType.toLowerCase()} units.`} />
        <meta name="keywords" content={`${formattedServiceType.toLowerCase()}, repair, maintenance, domestic appliances, Southeast Appliances`} />
        <link rel="canonical" href={`https://southeastappliances.com/services/${serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`} />
      </Helmet>
      
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8 text-primary-600">{title || `${formattedServiceType} Repair Services`}</h1>
        
        <p className="text-lg text-gray-700 text-center mb-12 max-w-3xl mx-auto">
          {description}
        </p>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Our {formattedServiceType} Repair Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {serviceDetails.map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-4">
                <h3 className="font-semibold text-lg mb-2 flex items-center">
                  <span className="text-primary-500 mr-2">✓</span>
                  {item}
                </h3>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Brands We Service</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {brands.map((brand, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-4 flex items-center justify-center">
                {logoMap[brand.logo] ? (
                  <img 
                    src={logoMap[brand.logo]} 
                    alt={brand.name} 
                    className="max-w-full max-h-16 object-contain"
                  />
                ) : (
                  <span className="text-lg font-semibold">{brand.name}</span>
                )}
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Service Areas for {formattedServiceType} Repair</h2>
          <p className="text-lg text-gray-700 mb-8">We offer our {formattedServiceType.toLowerCase()} repair services across various locations. Select your area to learn more about our services near you:</p>
          
          <div className="service-areas">
            <div className="locations grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {locations.map(location => renderLocationLinks(location))}
            </div>
          </div>
        </section>

        <div className="mt-16 text-center bg-primary-100 p-8 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-primary-600">Ready to Book Your {formattedServiceType} Repair?</h2>
          <p className="text-lg text-gray-700 mb-8">
            Our expert engineers are ready to help. Book your repair service now and get your {formattedServiceType.toLowerCase()} back to optimal performance.
          </p>
          <button 
            onClick={() => {
              console.log('Book Your Repair Now button clicked in ServiceTypeTemplate');
              openBookingForm();
            }}
            className="inline-block bg-primary-500 text-white font-bold py-3 px-6 rounded-full text-lg hover:bg-primary-600 transition-colors duration-300 shadow-md"
          >
            Book Your Repair Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeTemplate;
