const applianceTypes = [
  'Washing Machine',
  'Dryer',
  'Dishwasher',
  'Fridge',
  'Freezer',
  'Oven',
  'Cooker',
  'Cooker Hood',
  'Hob',
  'Microwave'
];

module.exports = applianceTypes;
