import React from 'react';
import applianceDetails from '../data/applianceDetails';

// Import all brand logos
import aegLogo from '../assets/logos/brand-logos/aeg.png';
import bekoLogo from '../assets/logos/brand-logos/beko.png';
import bellingLogo from '../assets/logos/brand-logos/belling.png';
import blombergLogo from '../assets/logos/brand-logos/blomberg.png';
import boschLogo from '../assets/logos/brand-logos/bosch.png';
import britanniaLogo from '../assets/logos/brand-logos/britannia.png';
import capleLogo from '../assets/logos/brand-logos/caple.png';
import electroluxLogo from '../assets/logos/brand-logos/electrolux.png';
import elicaLogo from '../assets/logos/brand-logos/elica.png';
import falconLogo from '../assets/logos/brand-logos/falcon.png';
import fisherPaykelLogo from '../assets/logos/brand-logos/fisher-paykel.png';
import haierLogo from '../assets/logos/brand-logos/haier.png';
import hisenseLogo from '../assets/logos/brand-logos/hisense.png';
import hooverLogo from '../assets/logos/brand-logos/hoover.png';
import hotpointLogo from '../assets/logos/brand-logos/hotpoint.png';
import indesitLogo from '../assets/logos/brand-logos/indesit.png';
import lamonaLogo from '../assets/logos/brand-logos/lamona.png';
import leisureLogo from '../assets/logos/brand-logos/leisure.png';
import lgLogo from '../assets/logos/brand-logos/lg.png';
import liebherrLogo from '../assets/logos/brand-logos/liebherr.png';
import lofraLogo from '../assets/logos/brand-logos/lofra.png';
import mieleLogo from '../assets/logos/brand-logos/miele.png';
import neffLogo from '../assets/logos/brand-logos/neff.png';
import rangemasterLogo from '../assets/logos/brand-logos/rangemaster.png';
import samsungLogo from '../assets/logos/brand-logos/samsung.png';
import siemensLogo from '../assets/logos/brand-logos/siemens.png';
import smegLogo from '../assets/logos/brand-logos/smeg.png';
import stovesLogo from '../assets/logos/brand-logos/stoves.png';
import whirlpoolLogo from '../assets/logos/brand-logos/whirlpool.png';
import zanussiLogo from '../assets/logos/brand-logos/zanussi.png';

const logoMap = {
  'aeg.png': aegLogo,
  'beko.png': bekoLogo,
  'belling.png': bellingLogo,
  'blomberg.png': blombergLogo,
  'bosch.png': boschLogo,
  'britannia.png': britanniaLogo,
  'caple.png': capleLogo,
  'electrolux.png': electroluxLogo,
  'elica.png': elicaLogo,
  'falcon.png': falconLogo,
  'fisher-paykel.png': fisherPaykelLogo,
  'haier.png': haierLogo,
  'hisense.png': hisenseLogo,
  'hoover.png': hooverLogo,
  'hotpoint.png': hotpointLogo,
  'indesit.png': indesitLogo,
  'lamona.png': lamonaLogo,
  'leisure.png': leisureLogo,
  'lg.png': lgLogo,
  'liebherr.png': liebherrLogo,
  'lofra.png': lofraLogo,
  'miele.png': mieleLogo,
  'neff.png': neffLogo,
  'rangemaster.png': rangemasterLogo,
  'samsung.png': samsungLogo,
  'siemens.png': siemensLogo,
  'smeg.png': smegLogo,
  'stoves.png': stovesLogo,
  'whirlpool.png': whirlpoolLogo,
  'zanussi.png': zanussiLogo,
};

const BrandItem = ({ name, logo }) => (
  <div className="bg-white rounded-lg p-4 shadow-md flex items-center justify-center transition-transform hover:scale-105">
    <div className="w-32 h-24 flex items-center justify-center">
      {logoMap[logo] ? (
        <img src={logoMap[logo]} alt={name} className="max-w-full max-h-full object-contain" />
      ) : (
        <span className="text-base font-semibold text-gray-800 text-center break-words">{name}</span>
      )}
    </div>
  </div>
);

const BrandsWeService = () => {
  const allBrands = Object.values(applianceDetails).flatMap(appliance => appliance.brands);
  const uniqueBrands = Array.from(new Set(allBrands.map(brand => brand.name)))
    .map(name => allBrands.find(brand => brand.name === name));

  return (
    <section className="py-16 bg-gray-50" id="brands">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-primary-600">Domestic Appliance Brands We Service</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {uniqueBrands.map((brand, index) => (
            <BrandItem key={index} name={brand.name} logo={brand.logo} />
          ))}
        </div>
        <p className="mt-12 text-center text-lg text-gray-600">
          Don't see your appliance brand listed? We likely still service it!{' '}
          <a href="/contact" className="text-primary-500 hover:text-primary-700 font-semibold">
            Contact us
          </a>{' '}
          for information about your specific appliance.
        </p>
      </div>
    </section>
  );
};

export default BrandsWeService;
