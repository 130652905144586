import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import BookingForm from './components/BookingForm';
import ServiceTypeTemplate from './components/ServiceTypeTemplate';
import LoaderWrapper from './components/LoaderWrapper';
import locations from './data/locations';
import applianceTypes from './data/applianceTypes';
import applianceDetails from './data/applianceDetails';
import { MAPBOX_API_KEY } from './mapboxConfig';

// Import ServicePageTemplate without lazy loading
import ServicePageTemplate from './components/ServicePageTemplate';

// Lazy load components
const ServiceAreasPage = lazy(() => import('./pages/ServiceAreasPage'));
const RegionOverview = lazy(() => import('./components/RegionOverview'));
const ServicesOverview = lazy(() => import('./pages/ServicesOverview'));

function ServicePageWrapper({ openBookingForm }) {
  const { location, region, town, service } = useParams();
  console.log('ServicePageWrapper params:', { location, region, town, service });

  const locationData = locations.find(loc => loc.name.toLowerCase().replace(/ /g, '-') === location.toLowerCase());
  console.log('LocationData:', locationData);

  let regionData, townData;
  if (locationData) {
    if (locationData.name === 'London') {
      regionData = locationData.regions.find(reg => reg.name.toLowerCase().replace(/ /g, '-') === region.toLowerCase());
    } else {
      regionData = locationData.regions[0];
    }
    console.log('RegionData:', regionData);

    if (regionData) {
      townData = regionData.towns.find(t => t.name.toLowerCase().replace(/ /g, '-') === town.toLowerCase());
    }
    console.log('TownData:', townData);
  }

  if (!locationData || !regionData || !townData) {
    console.log('Data not found');
    return <NotFound />;
  }

  return (
    <ServicePageTemplate
      location={locationData.name}
      region={regionData.name}
      town={townData.name}
      service={service}
      townData={townData}
      openBookingForm={openBookingForm}
    />
  );
}

function ServiceTypeWrapper({ service, openBookingForm }) {
  const details = applianceDetails[service] || {
    description: `We offer expert repair services for all types of ${service.toLowerCase()}. Our skilled technicians ensure your ${service.toLowerCase()} are running efficiently and reliably.`,
    serviceDetails: [`${service} diagnostics`, `${service} repair`, `${service} maintenance`],
    brands: []
  };

  return (
    <ServiceTypeTemplate
      serviceType={service}
      title={`${service} Repair Services`}
      description={details.description}
      serviceDetails={details.serviceDetails}
      brands={details.brands}
      openBookingForm={openBookingForm}
    />
  );
}

function RegionOverviewWrapper() {
  const { location, region } = useParams();
  console.log('RegionOverviewWrapper - location:', location, 'region:', region);
  
  const locationData = locations.find(loc => loc.name.toLowerCase().replace(/ /g, '-') === location.toLowerCase());
  console.log('RegionOverviewWrapper - locationData:', locationData);
  
  let regionData;
  if (locationData) {
    if (locationData.name === 'London') {
      regionData = locationData.regions.find(reg => reg.name.toLowerCase().replace(/ /g, '-') === region.toLowerCase());
    } else {
      regionData = locationData.regions[0];
    }
  }
  console.log('RegionOverviewWrapper - regionData:', regionData);

  // If region is not provided, use the location data as the region data
  if (!region && locationData) {
    regionData = {
      name: locationData.name,
      towns: locationData.regions[0].towns
    };
  }

  return <RegionOverview region={regionData} location={locationData ? locationData.name : ''} />;
}

function App() {
  const [isBookingFormOpen, setIsBookingFormOpen] = useState(false);

  const openBookingForm = () => {
    console.log('openBookingForm called in App.js');
    setIsBookingFormOpen(true);
  };
  const closeBookingForm = () => setIsBookingFormOpen(false);

  return (
    <HelmetProvider>
      <Router>
        <LoaderWrapper>
          <div className="App flex flex-col min-h-screen">
            <Helmet>
              <title>UK Domestic Appliance Repair - Expert Home Appliance Services</title>
              <meta name="description" content="Professional domestic appliance repair services across the UK. Fast, reliable, and expert repairs for all types of home appliances." />
            </Helmet>
            <Header openBookingForm={openBookingForm} />
            <main className="flex-grow">
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home openBookingForm={openBookingForm} mapboxApiKey={MAPBOX_API_KEY} />} />
                  <Route path="/service-areas" element={<ServiceAreasPage />} />
                  <Route path="/services" element={<ServicesOverview />} />
                  {applianceTypes.map((service, index) => (
                    <Route
                      key={index}
                      path={`/services/${service.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                      element={<ServiceTypeWrapper service={service} openBookingForm={openBookingForm} />}
                    />
                  ))}
                  <Route path="/service-areas/:location" element={<RegionOverviewWrapper />} />
                  <Route path="/service-areas/:location/:region" element={<RegionOverviewWrapper />} />
                  <Route path="/service-areas/:location/:town/:service" element={<ServicePageWrapper openBookingForm={openBookingForm} />} />
                  <Route path="/service-areas/:location/:region/:town/:service" element={<ServicePageWrapper openBookingForm={openBookingForm} />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </main>
            <Footer />
            <BookingForm isOpen={isBookingFormOpen} onClose={closeBookingForm} />
          </div>
        </LoaderWrapper>
      </Router>
    </HelmetProvider>
  );
}

export default App;
