import React from 'react';

export const InputWithIcon = ({ icon, type, name, value, onChange, placeholder, required, label, error }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <i className={`fas ${icon} text-gray-400`}></i>
      </div>
      {type === 'textarea' ? (
        <textarea
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className={`w-full pl-10 pr-3 py-2 rounded-lg border ${
            error ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-2 focus:ring-primary-600`}
          rows="4"
        ></textarea>
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className={`w-full pl-10 pr-3 py-2 rounded-lg border ${
            error ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-2 focus:ring-primary-600`}
        />
      )}
    </div>
    {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
  </div>
);

export const CheckboxWithLabel = ({ name, checked, onChange, label }) => (
  <div className="mb-4">
    <label className="flex items-center">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="form-checkbox h-5 w-5 text-primary-600"
      />
      <span className="ml-2 text-gray-700">{label}</span>
    </label>
  </div>
);

export const SelectWithIcon = ({ icon, name, value, onChange, options, required, label, error }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <i className={`fas ${icon} text-gray-400`}></i>
      </div>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className={`w-full pl-10 pr-3 py-2 rounded-lg border ${
          error ? 'border-red-500' : 'border-gray-300'
        } focus:outline-none focus:ring-2 focus:ring-primary-600 appearance-none`}
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <i className="fas fa-chevron-down text-gray-400"></i>
      </div>
    </div>
    {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
  </div>
);

export const RadioGroup = ({ name, value, onChange, options, label, error }) => (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
    <div className="mt-2">
      {options.map((option) => (
        <div key={option.value} className="flex items-center mb-2">
          <input
            id={`${name}-${option.value}`}
            name={name}
            type="radio"
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            className="form-radio h-4 w-4 text-primary-600"
          />
          <label htmlFor={`${name}-${option.value}`} className="ml-2 text-gray-700">
            {option.label}
          </label>
        </div>
      ))}
    </div>
    {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
  </div>
);

export const DatePickerWithIcon = ({ icon, name, value, onChange, required, label, error }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <i className={`fas ${icon} text-gray-400`}></i>
      </div>
      <input
        type="date"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className={`w-full pl-10 pr-3 py-2 rounded-lg border ${
          error ? 'border-red-500' : 'border-gray-300'
        } focus:outline-none focus:ring-2 focus:ring-primary-600`}
      />
    </div>
    {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
  </div>
);

export const TimePickerWithIcon = ({ icon, name, value, onChange, required, label, error }) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <i className={`fas ${icon} text-gray-400`}></i>
      </div>
      <input
        type="time"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className={`w-full pl-10 pr-3 py-2 rounded-lg border ${
          error ? 'border-red-500' : 'border-gray-300'
        } focus:outline-none focus:ring-2 focus:ring-primary-600
        text-base sm:text-sm md:text-base
        appearance-none
        [&::-webkit-calendar-picker-indicator]:bg-transparent
        [&::-webkit-calendar-picker-indicator]:dark:bg-transparent
        [&::-webkit-datetime-edit-fields-wrapper]:p-0
        [&::-webkit-date-and-time-value]:text-black
        [&::-webkit-datetime-edit]:text-black
        [&::-webkit-datetime-edit-hour-field]:text-black
        [&::-webkit-datetime-edit-minute-field]:text-black
        [&::-webkit-inner-spin-button]:appearance-none`}
      />
    </div>
    {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
  </div>
);

// ... (keep all other components unchanged)
